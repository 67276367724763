import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './home.scss'; // Assuming you have a CSS file for styles

const EmployeeHome = () => {
  const navigate = useNavigate();

  // State management
  const [userName, setUserName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [announcements, setAnnouncements] = useState([]);
  const [showAllAnnouncements, setShowAllAnnouncements] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showAllSchedules, setShowAllSchedules] = useState(false); // New state for uploaded schedules toggle
  const [showAllFiles, setShowAllFiles] = useState(false);


  // Function to toggle Uploaded Schedules
  const toggleSchedules = () => {
    setShowAllSchedules((prev) => !prev);
  };
  


  // Retrieve user ID from local storage (assuming set during login)
  const userId = localStorage.getItem('userId');

  // Fetch user profile on component mount
  useEffect(() => {
    const fetchUserName = async () => {
      try {
        // Add a check to redirect if no userId
        if (!userId) {
          navigate('/login');
          return;
        }

        const response = await axios.get(`https://employeeapi.jetsonweb.com/api/employees/profile`, {
          params: { userId },
          withCredentials: true
        });

        setUserName(response.data.full_name);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user name:', error);
        setError('Failed to load user profile');
        setLoading(false);

        // Redirect to login if fetch fails
        navigate('/login');
      }
    };

    fetchUserName();
  }, [userId, navigate]);

  // Fetch announcements on component mount
  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await axios.get('https://employeeapi.jetsonweb.com/api/announcements');
        setAnnouncements(response.data);
      } catch (error) {
        console.error('Error fetching announcements:', error);
        setError('Failed to load announcements');
      }
    };

    fetchAnnouncements();
  }, []);


  useEffect(() => {
    const fetchUploadedFiles = async () => {
      try {
        const response = await axios.get('https://employeeapi.jetsonweb.com/api/uploadfile');
        console.log('Uploaded files:', response.data); // Log the response data
        setUploadedFiles(response.data); // Update state with the data
      } catch (error) {
        if (error.response) {
          console.error('Server responded with error:', error.response.status, error.response.data);
        } else if (error.request) {
          console.error('No response received:', error.request);
        } else {
          console.error('Error setting up request:', error.message);
        }
      }
    };
  
    fetchUploadedFiles(); // Actually call the function
  }, []); // Empty dependency array means this runs once on component mount
  
  // Handle "See More Announcements"
  const toggleAnnouncements = () => {
    setShowAllAnnouncements((prev) => !prev);
  };

  // Handle Logout
  const handleSignout = (event) => {
    event.preventDefault();

    // Clear user data from local storage
    localStorage.removeItem('userId');
    localStorage.removeItem('token');

    // Redirect to login page
    navigate("/login");
  };

  // Handle announcement click
  const handleAnnouncementClick = (announcement) => {
    setSelectedAnnouncement(announcement);
  };
  const handleFileClick = (file) => {
    setSelectedFile(file);
  };
  // Close popup
  const closePopup = () => {
    setSelectedAnnouncement(null);
  };
  const closePopup1 = () => {
    setSelectedFile(null);
  };
// Handle file click to open the file or show popup
const handleFileDownload = (fileUrl) => {
  if (fileUrl) {
    window.open(fileUrl, '_blank'); // Open the file in a new tab
  } else {
    alert('File not available');
  }
};



  // Render loading state
  if (loading) {
    return (
      <div className="loading-container">
        <p>Loading your dashboard...</p>
      </div>
    );
  }

  // Render error state
  if (error) {
    return (
      <div className="error-container">
        <p>{error}</p>
        <button onClick={() => navigate('/login')}>Return to Login</button>
      </div>
    );
  }
  // Function to handle file download/open
  const handleFileClick1 = (file) => {
    setSelectedFile(file);
  };
  

// Updated handleFileClick to directly open the file

  
  return (
    <div className="home-container">
      {/* Header Section */}
      <header className="home-header">
        <div className="header-content">
          <h1>Welcome, {userName || 'Employee'}</h1>
          <button onClick={handleSignout} className="logout-button">
            Logout
          </button>
        </div>
      </header>

      {/* Main Content */}
      <main className="main-content">
        {/* Announcements Section */}
        <section className="content-section">
          <h2>Announcements</h2>
          <div className="content-grid">
            {announcements
              .slice(0, showAllAnnouncements ? announcements.length : 3)
              .map((item) => (
                <div
                  key={item.id}
                  className="content-card"
                  onClick={() => handleAnnouncementClick(item)}
                >
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                  <p className="announcement-date">{item.date}</p>
                </div>
              ))}
          </div>
          <div className="see-more">
          <a href="#" onClick={toggleAnnouncements}>
            {showAllAnnouncements ? "See Less Announcements" : "See More Announcements"}
          </a>
        </div>
        </section>
        <section className="files-section">
        <h2>Uploaded Schedules</h2>
        <div className="files-grid">
        <div className="file-container">
      
        {uploadedFiles
          .slice(0, showAllFiles ? uploadedFiles.length : 3)
          .map((file, index) => (
            <div 
              key={index} 
              className="file-card" 
              onClick={() => handleFileClick(file)}
            >
              <h3>{file.title}</h3>
              <p className="file-date">Date: {file.announcement_date}</p>
            </div>
          ))}
      </div>

        </div>
        <div className="see-more">
        <a href="#" onClick={() => setShowAllFiles(!showAllFiles)}>
            {showAllFiles ? "See Less" : "See More "}
          </a>
          </div>

              </section>
                      {/* Popup for Announcement Details */}
                    {/* Popup for Announcement Details */}
              {/* Popup for Announcement Details */}
              {selectedAnnouncement && (
                <div className="popup-overlay" onClick={closePopup}>
                  <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                    <h3>{selectedAnnouncement.title}</h3>
                    <p>{selectedAnnouncement.description}</p>
                    <p>Date: {selectedAnnouncement.date}</p>
                    
                    {selectedAnnouncement.file_paths && (
                      <button 
                        onClick={() => handleFileDownload(selectedAnnouncement.file_paths)} 
                        className="download-button"
                      >
                        Download File
                      </button>
                    )}
                    <button onClick={closePopup} className="close-popup">Close</button>
                  </div>
                </div>
              )}

              {/* Popup for Uploaded Schedules */}
              {selectedFile && (
                <div className="popup-overlay" onClick={closePopup1}>
                  <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                    <h3>{selectedFile.title}</h3>
                    <p>{selectedFile.description || 'No description available'}</p>
                    <p>Date: {selectedFile.announcement_date}</p>
                  
                    {selectedFile.file_path && (
                      <button 
                        onClick={() => handleFileDownload(selectedFile.file_path)} 
                        className="download-button"
                      >
                        Download File
                      </button>
                    )}
                    <button onClick={closePopup1} className="close-popup">Close</button>
                  </div>
                </div>
              )}



      </main>

      {/* Footer */}
      <footer id="contact" className="footer">
        <p>
          For inquiries, contact us at{' '}
          <a href="mailto:info@jetsondoro.com">info@jetsondoro.com</a>
        </p>
      </footer>
    </div>
  );
};

export default EmployeeHome;
