import React, { useState } from 'react';
import './login.scss';
import Logo from '../assets/photo_2_2024-08-17_15-14-53.jpg';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [inputPassword, setInputPassword] = useState('');
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  const navigate = useNavigate();

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Handle login
  const handleLogin = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setMessage(null);

    try {
      const response = await axios.post('https://employeeapi.jetsonweb.com/api/login', {
        username,
        password: inputPassword,
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      // Handle successful login
      if (response.data.status === 'success') {
        const { userId, isApproved } = response.data;

        // Store user information securely
        localStorage.setItem('userId', userId);
        localStorage.setItem('isApproved', isApproved);

        setMessage('Login successful');
        setMessageType('success');

        // Redirect if the user is approved
        if (isApproved) {
          setTimeout(() => {
            navigate('/Home');
          }, 1500);
        } else {
          setMessage('Your account is not approved.');
          setMessageType('error');
          setIsLoading(false);
        }
      }
    } catch (error) {
      // Comprehensive error handling
      const errorMessage = error.response?.data?.message || 
                           'Something went wrong. Please try again later.';
      
      setMessage(errorMessage);
      setMessageType('error');
      setIsLoading(false);

      // Clear sensitive information
      setInputPassword('');
    }
  };

  return (
    <div className="login-container">
      <div className="top">
        <div className="wrapper">
          <img 
            className="logo" 
            src={Logo} 
            alt="Company Logo" 
            onError={(e) => {
              e.target.style.display = 'none';
              console.error('Logo failed to load');
            }}
          />
        </div>
      </div>
      <div className="form-containerL">
        <h1 className="title">Login</h1>
        <form onSubmit={handleLogin} noValidate>
          <input
            type="text"
            className="input-field"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            autoComplete="username"
          />
          <div className="password-container">
            <input
              type={showPassword ? 'text' : 'password'}
              className="input-field"
              placeholder="Password"
              value={inputPassword}
              onChange={(e) => setInputPassword(e.target.value)}
              required
              autoComplete="current-password"
            />
            <button
              type="button"
              className="toggle-password"
              onClick={togglePasswordVisibility}
              aria-label={showPassword ? 'Hide password' : 'Show password'}
            >
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </button>
          </div>
          <button
            type="submit"
            className="login-button"
            disabled={isLoading || !username || !inputPassword}
          >
            {isLoading ? 'Logging In...' : 'Log In'}
          </button>
        </form>
        {message && (
          <div 
            className={`message ${messageType}`} 
            role="alert"
          >
            {message}
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;